import { PropsWithChildren } from "react";

export const Panel: React.FC<
  PropsWithChildren<{
    className?: string;
    title?: React.ReactNode;
    titleClassName?: string;
    containerClassName?: string;
    titleControls?: React.ReactNode;
    subtitle?: string;
  }>
> = ({
  children,
  title,
  titleClassName,
  className,
  titleControls,
  containerClassName,
  subtitle,
}) => {
  return (
    <div
      className={`bg-white dark:bg-black  shadow sm:rounded-lg ${containerClassName} `}
    >
      {title ? (
        <div
          className={`flex items-center px-4 sm:px-6 pt-5 py-4 ${titleClassName}`}
        >
          <h3 className="text-base font-semibold leading-6 text-gray-900 flex-1 dark:text-gray-200">
            {title}
          </h3>
          {titleControls ? titleControls : null}
        </div>
      ) : null}
      {subtitle ? (
        <div className="text-sm text-gray-600 px-4 sm:px-6">{subtitle}</div>
      ) : null}
      <hr className="border-gray-200 dark:border-gray-700" />
      <div className={`px-6 py-6 sm:px-12 ${className || ""}`}>{children}</div>
    </div>
  );
};
